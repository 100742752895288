<template>
  <div>
    <div class="container form-signin3">
      <div class="card-bg col-md-12 dealer-form form-signin3 mt-0">
        <div class="container">
          <div class="border-page">
            <Form ref="account" @submit="save()">
              <div class="col-12 form row mb-2">
                <div class="col-3">
                  <label class="col-form-label" style="display: flex">
                    Primary Color
                  </label>
                  <Field
                    type="color"
                    class="form-control"
                    name="primary_color"
                    placeholder="primary_color"
                    v-model="details.primary_color" />
                </div>

                <div class="col-3">
                  <label class="col-form-label" style="display: flex">
                    Secoundry Color
                  </label>
                  <Field
                    type="color"
                    class="form-control"
                    name="secoundry_color"
                    placeholder="secoundry_color"
                    v-model="details.secoundry_color" />
                </div>

                <div class="col-3">
                  <label class="col-form-label" style="display: flex">
                    Primary Font Color
                  </label>
                  <Field
                    type="color"
                    class="form-control"
                    name="primary_font_color"
                    placeholder="primary_font_color"
                    v-model="details.primary_font_color" />
                </div>

                <div class="col-3">
                  <label class="col-form-label" style="display: flex">
                    Secoundry Font Color
                  </label>
                  <Field
                    type="color"
                    class="form-control"
                    name="secoundry_font_color"
                    placeholder="secoundry_font_color"
                    v-model="details.secoundry_font_color" />
                </div>

                <div class="col-md-12" style="display: flex">
                  <button
                    class="save-btn px-4 ml-3"
                    type="submit"
                    id="save-button">
                    Save
                  </button>
                </div>
              </div>
              <div class="col-md-12 text-right"></div>
            </Form>
          </div>
        </div>
      </div>
    </div>
    <ErrorComponent @retry="getDetails()" ref="errorComponent" />
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
export default {
  components: {
    Form,
    Field,
    ErrorComponent,
  },
  name: "settings",
  data() {
    return {
      details: {
        primary_color: "",
        secoundry_color: "",
        primary_font_color: "",
        secoundry_font_color: "",
      },
    };
  },
  mounted() {
    this.getDetails();
  },
  methods: {
    getDetails() {
      this.$refs.errorComponent.updateFormLoader(true);
      this.$api
        .getAPI({
          _action: "/settings",
        })
        .then((res) => {
          this.details = res;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    save() {
      this.$api
        .putAPI({
          _action: "/theme-update",
          _body: this.details,
          _buttonId: "save-button",
        })
        .then((res) => {
          if (res) {
            this.$notify({
              title: "Success",
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {});
    },
  },
};
</script>
